<template>
  <!--柱状图-->
  <div :id="id" :style="`height: ${height}px`" class="chart" />
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector';

export default {
  props: {
    id: {
      type: [String, Number],
      default: 'chart',
    },
    subtext: {
      type: String,
      default: '',
    },
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 图表数据
    data: {
      type: Object,
      default: () => {},
    },
    // 图表高度
    height: {
      type: Number,
      default: 200,
    },
    // 图表区域上侧边距
    gridTop: {
      type: Number,
      default: 35,
    },
    // 图表区域下侧边距
    gridBottom: {
      type: Number,
      default: 10,
    },
    // 图表区域左侧边距
    gridLeft: {
      type: Number,
      default: 15,
    },
    // 图表区域右侧边距
    gridRight: {
      type: Number,
      default: 0,
    },
    // 图表类型
    type: {
      type: String,
      default: 'bar',
    },
    // 是否显示图例
    legendShow: {
      type: Boolean,
      default: true,
    },
    // 柱形颜色
    barColor: {
      type: Array,
      default: () => ['#006df0', '#0acf97', '#fc7f03', '#d149fe', '#fe6d65', '#fcd02d', '#9ad864'],
    },
  },
  data() {
    return {
      // 图表配置
      optionEcharts: {
        // 标题
        title: {
          text: this.title,
          subtext: this.subtext,
          top:'-10px',
          right: 0
        },
        tooltip: {
          trigger: 'axis',
          confine: true, // 限制在图表区域内
        },
        // 图例
        legend: {
          show: this.legendShow,
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            // color: '#ffffff'
          },
          bottom: '0%',
          left: '45%',
        },
        // 数据集
        dataset: {
          source: this.data.source,
        },
        // 图表区域大小
        grid: {
          top: this.gridTop,
          bottom: this.gridBottom,
          left: this.gridLeft,
          right: this.gridRight,
          containLabel: true, // 包含标签
        },
        // X轴
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            padding: [0, 0, 0, 0],
            // rotate: 20, // 旋转
            color: '#231815', // 坐标字色
            fontSize: '12',
          },
          // 坐标轴线
          axisLine: {
            lineStyle: {
              color: '#CACACA',
              width: 2,
            },
          },
        },
        // y轴
        yAxis: {
          type: 'value',
          name: '',
          offset: 0, // 偏移
          position: 'left', // 位置
          axisLabel: {
            formatter: '{value}', // 61A0A8
            padding: [0, 15, 0, 0],
            color: '#231815', // 坐标字色
            fontSize: '14',
          },
          nameTextStyle: {
            // color: '#fff'
          },
          // 刻度线
          axisTick: {
            show: false,
          },
          // 坐标轴线
          axisLine: {
            show: true,
            lineStyle: {
              color: '#CACACA',
              width: 2,
            },
          },
          // x轴网格线颜色
          splitLine: {
            lineStyle: {
              color: '#CACACA',
              type: [1, 2], // 线的类型
            },
          },
        },
        series: [],
      },
    };
  },
  watch: {
    // 监听数据集
    data: {
      deep: true,
      handler(val) {
        console.log(val);
        this.optionEcharts.dataset.source = val.source;
        this.initChart();
      },
    },
  },
  mounted() {
    this.charData(); // 处理数据
    this.initChart(); // 初始化echarts实例
  },
  methods: {
    charData() {
      // y轴数据
      this.optionEcharts.yAxis = this.data.yAxis || this.optionEcharts.yAxis;
      // 系列
      this.data.series.forEach((item, index) => {
        this.optionEcharts.series.push({
          type: item.type, // 图表类型
          barWidth: item.barWidth, // 柱宽度
          markLine: item.markLine, // 平均线
          barMaxWidth: item.barMaxWidth, // 柱宽度
          yAxisIndex: item.yAxisIndex, // 有多条y轴时，设置y轴索引
          color: this.barColor[index], // 柱形颜色
        });
      });
    },
    initChart() {
      // const elementResizeDetectorMaker = require('element-resize-detector');
      const echarts = require('echarts');
      const chartList = document.getElementById(this.id);
      // 初始化echarts实例
      const chart = echarts.init(chartList);
      this.$nextTick(() => {
        // 图表配置
        chart.setOption(this.optionEcharts);
        // 图表的自适应
        window.addEventListener('resize', () => {
          chart.resize();
        });
        // 图表的自适应
        elementResizeDetectorMaker().listenTo(chartList, () => {
          chart.resize();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  min-width: 250px;
  width: 100%;
}
</style>
